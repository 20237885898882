import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
// Components
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import useRecommended from "../hooks/useRecommended"
import ReactPaginate from "react-paginate"
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa"
import { Seo } from "../components/seo"
const Category = () => {
  const dataRecommended = useRecommended()
  const [postsPerPage] = useState(10)
  const [offset, setOffset] = useState(1)
  const [allDataArticle, setAllDataArticle] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)

  const getPostData = data => {
    return data.map((recommended, i) => (
      <li key={i}>
        <div>
          <span>{recommended.DatePublication}</span>
          <Link to="/category/オススメのメキシコ求人">
            オススメのメキシコ求人
          </Link>
        </div>
        <Link to={`/osusume${recommended.Slug}`}>{recommended.Title}</Link>
      </li>
    ))
  }

  const getAllPosts = async () => {
    const data = await dataRecommended
    const indexLast = postsPerPage * selectedPage
    const indexFirst = indexLast - postsPerPage
    const slice = data.slice(indexFirst, indexLast)
    const postData = getPostData(slice)
    setAllDataArticle(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  }

  const handlePageClick = e => {
    setSelectedPage(e.selected + 1)
    setOffset(selectedPage * 10)
  }

  useEffect(() => {
    getAllPosts()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  return (
    <>
      <Seo
        title={`オススメのメキシコ求人 | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container category">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="category__list-content">
              <SectionTitle>オススメのメキシコ求人</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="category__list-content--list">
                <ul>{allDataArticle}</ul>
              </div>
              <ReactPaginate
                previousLabel={<FaAngleDoubleLeft />}
                nextLabel={<FaAngleDoubleRight />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"content-pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default Category
